import { getAxiosConfig } from '../apisSetup/apiConfig';
import nodeUtils from '../../projectSetups/nodeUtils';

import { formatEmployeesData, getCookie, setCookie, getOrgId, setLocalStorage, getLocalStorage, removeLocalStorage, saveServerLogs } from 'UtilityComponents/Util';
import { APPLICATION_CONSTANTS, PATHS } from 'AppConstants';

const axiosApiCaller = getAxiosConfig(PATHS.MYBIZ_API_URL, true, {}, false);

const TEN_MINUTES = 0.007;

/**
 * get ab experiment data from pokus and cache it in localstorage to avoid repetitive calls
 * @author mmt8270
 * @param { String } lob platform/lob value for which ab to be fetched
 * @param { Boolean } isAuth if mmt-auth to be sent
 * @return { Promise } promise that resolves when ab api is success or data is in cache and resolves with the lob exp data
 */
export const getAbExperiment = async (lob, isAuth) => {
	let experimentData = getLocalStorage('experimentData');
	const abCookie = getCookie('abDataKey');
	const configStorageKey = lob;
	const orgID = getOrgId()?.toString();
	let abData;
	if (experimentData && abCookie && !isAuth) {
		experimentData = JSON.parse(experimentData);
		abData = experimentData && experimentData[configStorageKey];
		if (abData?.orgID !== orgID) {
			abData = false;
			removeLocalStorage('experimentData');
		}
	} else {
		experimentData = {};
	}
	if (!abData) {
		const dvidCookie = getCookie('dvid');
		const mmtAuth = isAuth ? getCookie('mmt-auth') : false;
		const dvidVal = dvidCookie || nodeUtils.getUUID();

		if (typeof dvidCookie === 'undefined') {
			setCookie('dvid', dvidVal, 365, APPLICATION_CONSTANTS.MMT_COOKIE_DOMAIN);
		}

		const abLob = lob ? lob : APPLICATION_CONSTANTS.SIGNUP_AB_LOB;
		const orgParam = lob && orgID;

		return new Promise((resolve, reject) => {
			nodeUtils.abApi(dvidVal, abLob, orgParam, mmtAuth).then((res) => {
				const myBizExpMeta = res.data.perLobMap && res.data.perLobMap[lob] && res.data.perLobMap[lob].metadataValues;
				myBizExpMeta.orgID = orgID;
				try {
					experimentData[configStorageKey] = myBizExpMeta;
					if (!isAuth) {
						setLocalStorage('experimentData', JSON.stringify(experimentData));
						setCookie('abDataKey', new Date().getTime(), TEN_MINUTES, APPLICATION_CONSTANTS.MYBIZ_COOKIE_DOMAIN);
					}
				} catch (error) {
					saveServerLogs(error.stack, 'error', 'not able to process ab data');
					reject(error);
				}
				resolve(myBizExpMeta);
			}).catch((err) => {
				saveServerLogs(err.stack, 'error', 'not able to fetch ab data');
				reject(err);
			});
		});
	} else return Promise.resolve(abData);
};

/**
 * get config data from girgit and cache it in localstorage to avoid repetitive calls
 * @author mmt8270
 * @param { String } dataType data that  is required
 * @param { String } isAuth if mmt-auth to be sent
 * @return { Promise } promise that resolves when ab api is success or data is in cache and resolves with the config data
 */
export const getconfigData = async (dataType, isAuth) => {
	let configStoreData = getLocalStorage('configData');
	const configCookie = getCookie('configKey');
	const configStorageKey = dataType;
	let configData;
	if (configStoreData && configCookie && !isAuth) {
		configStoreData = JSON.parse(configStoreData);
		configData = configStoreData && configStoreData[configStorageKey];
	} else {
		configStoreData = {};
	}
	if (!configData) {
		const response = await axiosApiCaller.get(PATHS.CCB_URL + PATHS.GET_CONFIG_DATA + dataType);
		const respData = response && response.data;
		if (respData && respData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			try {
				configData = configStoreData[configStorageKey] = respData.config;
				if (!isAuth) {
					setLocalStorage('configData', JSON.stringify(configStoreData));
					setCookie('configKey', APPLICATION_CONSTANTS.TODAY.getTime(), TEN_MINUTES, APPLICATION_CONSTANTS.MYBIZ_COOKIE_DOMAIN);
				}
			} catch (error) {}
		}
	}
	return configData;
};

/**
 * Polls the job status using the provided API request and callbacks.
 *
 * @param {Object} apiRequest - The API request object.
 * @param {Function} successCb - The callback function to be called on successful response.
 * @param {Function} failureCb - The callback function to be called on failure or error.
 */
export const pollJobStatus = async (apiRequest, successCb, failureCb) => {
	let response = null;
	const url = PATHS.POLL_JOB_STATUS;
	try {
		response = await axiosApiCaller.post(PATHS.CCB_URL + url, apiRequest);
		if (response.data?.data) {
			successCb(response.data?.data);
		} else if (response.data?.retry) {
			setTimeout(() => {
				pollJobStatus(apiRequest, successCb, failureCb);
			}, (response.data.retryDelay || APPLICATION_CONSTANTS.POLL_INTERVAL));
		} else {
			failureCb();
		}
	} catch (e) {
		failureCb();
		saveServerLogs(e.stack + JSON.stringify(apiRequest), 'error', 'pollJobStatus');
		return;
	}
};

/**
 * utility functions to get employee list based on search criterion
 * @author mybizFe
 * @param { String } searchString
 * @param { Number } debounceTime time delay between inputs
 * @param { function } callback custom formatter
 * @return { Array } exports util functions { formatConfigData }
 */
export const getEmployeeList = async (searchString, debounceTime = 2000, callback) => {
	let formattedData = [];
	try {
		const apiRequest = {
			pattern: searchString,
			from: 0,
			pagesize: 20,
			crossLeSearchContext: APPLICATION_CONSTANTS.CROSS_LE_MAPPING_TYPE.ORG_TO_ORG
		};
		const response = await axiosApiCaller.post(`${PATHS.CCB_URL}${PATHS.EMPLOYEE_AUTOSUGGEST}`, apiRequest);
		const { data } = response || {};
		if (data && data.employees) {
			if (callback) {
				return callback(data.employees);
			}
			formattedData = formatEmployeesData(data.employees);
		}
		return formattedData;
	} catch (err) {
		saveServerLogs(err.stack, 'error', PATHS.EMPLOYEE_AUTOSUGGEST);
		return false;
	}
};
