import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import loadable from 'react-loadable';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import useDeviceDetect from 'UtilityComponents/customeHooks/useDeviceDetect';
import { DisplayProvider, Devices } from 'UtilityComponents/displayContext';
import { trackClickEvents } from './utils';
import { decodeInviteCode, getCookie, clearLoginState, setLocalStorage, getLocalStorage, isValidUrl } from 'UtilityComponents/Util';

import { LandingProvider } from './landingContext';
import Theme from 'common/Theme';
import { HiddenElem, AppWrapper, HeaderWrap } from './Styles';
import { PATHS, APPLICATION_CONSTANTS, MODAL_NAMES, LANDING_CONSTS } from 'AppConstants';

import RequestDemo from './components/requestDemo';
import { landingRadioFields } from '../common/HelpSection/callbackConfig';
import Footer from 'common/LandingFooter/Footer';

import DHeader from '../MMTHeader';
import '../MMTHeader/mobileHeader.css';
import Header from 'common/LandingHeader/Header';
import ChatBot from 'common/ChatBot/reduxLoad';
import CircularLoader from 'common/CircularLoader/CircularLoader';
import GlobalStyle from 'common/GlobalStyles/GlobalStyles';
import { SIGNUP_BTN_TEXT } from './config';

const MobileLanding = loadable({
	loader: () => import('common/MobileLanding/MobileLanding' /* webpackChunkName: 'MobileLanding' */),
	loading: () => null,
	modules: ['MobileLanding']
});

const landingComps = [
	'Benefits',
	'GettingStarted',
	'DownloadSection',
	'Testimonials',
	'CaseStudies',
	'Questionnaire'
];

const LandingTemplate = ({ loggedInHandler,
	isMobile,
	isPreLoad,
	children,
	showChatBot,
	headerLinks = [],
	headerConfig,
	navOverHandler,
	scrollOffset = 60,
	headerActions = {},
	isMobLoginRedirect = true,
	loginRedirectUrl = '',
	isLoggedInUser,
	enableDropff,
	isSME = false,
	isLanding,
	contextData,
	showFooter = true }) => {
	const devicedetect = useDeviceDetect();

	const history = useHistory();

	const isMobileView = isMobile || devicedetect.isMobile;
	const isLandScape = devicedetect.isLandScape;

	const { search, hash, pathname } = useLocation();
	const { path } = useRouteMatch(PATHS.CORPORATE_SEO_PAGE) || {};

	const params = queryString.parse(search, { parseNumbers: true, parseBooleans: true }) || {};

	const { signup, email, redirectUrl = loginRedirectUrl, invitecode, modal = false, sessionExpiry, userInactivity, cmp, referralCode } = params;

	const [mobileLogin, setmobileLogin] = useState(isMobileView && (isMobLoginRedirect && isLoggedInUser));
	const [isMyBizLogin, setisMyBizLogin] = useState(false);
	const [activeModal, setactiveModal] = useState(false);
	const [bizModal, setbizModal] = useState(false);
	const [isScollView, setIsscrollView] = useState('');
	const [myBizData, setMybizData] = useState({
		isB2cApp: false,
		myBizFullSignUp: true
	});

	const mybizExperiments = { mbSignupWithOTP: true, userDropCallbackType: LANDING_CONSTS.REQ_CALLBACK, newBusinessIdentifiers: true, mybiz_show_password: false };
	const isContactUs = pathname === PATHS.CONTACT_US;
	const isInvite = pathname === PATHS.INVITE_PAGE;

	const [visibleComps, setVisibleComps] = useState({
		'Benefits': isPreLoad,
		'GettingStarted': isPreLoad,
		'DownloadSection': isPreLoad,
		'Testimonials': isPreLoad,
		'CaseStudies': isPreLoad,
		'Questionnaire': isPreLoad
	});
	const [loadHeader, setLoadHeader] = useState((sessionExpiry || userInactivity) ? false : signup);
	const [currElem, setCurrElem] = useState(false);
	const [detailBox, setDetailBox] = useState(false);
	const [showBot, setShowBot] = useState(false);
	const [loadBot, setIsloadBot] = useState(false);
	const [showHeaderLoader, setShowHeaderLoader] = useState(false);
	const hideDropoff = getLocalStorage('hideDropoff');
	const [enableDropffPop, setEnableDropffPop] = useState(false);
	const [isAutoPopup, setIsAutoPopup] = useState(false);
	const [autoPopupAttempted, setAutoPopupAttempted] = useState(false);

	const isDropOff = bizModal === MODAL_NAMES.DROPOFF;

	let isLoggedIn = false;

	const toggleModal = useCallback((modal) => {
		setbizModal(modal);
		if (!modal) {
			document.getElementsByTagName('body')[0].classList.remove('ovf-hidden');
		} else {
			document.getElementsByTagName('body')[0].classList.add('ovf-hidden');
		}
	}, []);

	useEffect(() => {
		const mmtauth = getCookie(APPLICATION_CONSTANTS.MMT_AUTH_COOKIE);
		if (isMobLoginRedirect && (isMobile || isMobileView) && mmtauth) {
			setmobileLogin(true);
		}
	}, [isMobile, isMobileView]);

	const openDropoff = useCallback(() => {
		toggleModal(MODAL_NAMES.DROPOFF);
	}, []);

	setTimeout(() => {
		setEnableDropffPop(enableDropff);
	}, LANDING_CONSTS.DROPOFF_DELAY);

	const onModalClose = useCallback((modal) => {
		if (modal === '') {
			toggleModal(false);
			setactiveModal(false);
			setisMyBizLogin(false);
			if (!isLoggedIn) {
				setShowHeaderLoader(false);
			}
			setMybizData((data) => ({
				isB2cApp: false,
				myBizFullSignUp: true
			}));

			/* Auto popup - Popup close */
			if (isAutoPopup) {
				trackClickEvents('myBiz_signup_auto_popup_cancelled', 'prop44');
				setIsAutoPopup(false);
			}
		}
	}, [isAutoPopup]);

	const onLoginComplete = useCallback(async () => {
		isLoggedIn = true;
		setLoadHeader(false);
		toggleModal(false);

		if (isMobileView) {
			isLoggedIn = false;
			setmobileLogin(true);
		} else if (redirectUrl && isValidUrl(redirectUrl)) {
			window.location.href = redirectUrl;
		} else {
			const cmpurl = cmp ? `&cmp=${cmp}` : '';
			window.location.href = `${PATHS.MMT_MYBIZ}&modal=mybiz_login${cmpurl}`;
		}
	}, [isMobileView, redirectUrl, isAutoPopup]);

	const onLoginClicked = useCallback((e, email) => {
		e && e.stopPropagation();
		if (isMobileView) {
			window.location = `${PATHS.BIZ_PWA_URL}${search}`;
			return false;
		}
		if (email) {
			setMybizData((data) => ({
				...data,
				email
			}));
		}
		setShowHeaderLoader(true);
		if (!loadHeader) {
			setLoadHeader(true);
		}

		toggleModal(APPLICATION_CONSTANTS.MYBIZ_LOGIN);
		setisMyBizLogin('new');

		setTimeout(() => {
			setactiveModal(APPLICATION_CONSTANTS.MYBIZ_LOGIN);
			document.getElementsByTagName('body')[0].classList.add('ovf-hidden');
		});

		if (isMobileView && MobileLanding.preload) {
			MobileLanding.preload();
		}
	}, [isMobileView, loadHeader, toggleModal]);

	const loadscrollToElem = (compname) => {
		updateShowComp(compname);
		setTimeout(() => {
			setIsscrollView(compname);
		});
	};

	const onSignUpClicked = useCallback((e, email) => {
		if (isLoggedInUser) {
			loggedInHandler ? loggedInHandler() : window.location = PATHS.MMT_BIZ_PROFILE_URL;
		} else {
			/* If signup button clicked before auto signup popup then update autoPopupAttempted */
			!autoPopupAttempted && setAutoPopupAttempted(true);
			onLoginClicked(e, email);
		}
	}, [isLoggedInUser, loggedInHandler, onLoginClicked]);

	const signinClicked = useCallback((e) => {
		trackClickEvents('signup|in_clicked');
		onSignUpClicked(e);
	}, [onSignUpClicked]);

	const navClick = useCallback((e) => {
		e.preventDefault();
		if (e && e.target && e.target.dataset) {
			const defaultPageUrl = (path === PATHS.CORPORATE_SEO_PAGE) || isSME ? pathname : PATHS.LANDING_PAGE;
			const { link = '', pageurl = defaultPageUrl, trackval } = e.target.dataset;
			const compname = link;
			const element = document.getElementById(compname);
			let newLocation = pageurl;
			if (compname) {
				newLocation = newLocation + '#' + compname;
			}
			try {
				if (element) {
					const bodyRect = document.body.getBoundingClientRect();
					const elemRect = element.getBoundingClientRect();
					const elementPosition = elemRect.top - bodyRect.top;
					const headerOffset = 80;
					const offsetPosition = elementPosition - headerOffset;

					window.scrollTo({
						top: offsetPosition,
						behavior: 'smooth'
					});
					setTimeout(() => {
						window.history.pushState({}, '', newLocation);
					});
				} else if (pathname === pageurl && compname) {
					loadscrollToElem(compname);
					if (!e.scollMore) {
						window.history.pushState({}, '', newLocation);
					}
				} else {
					window.location = newLocation;
				}
			} catch (error) {
				window.location = newLocation;
			}
			trackClickEvents(trackval);
		}
	}, [updateShowComp, pathname, history]);

	const updateShowComp = useCallback((comp, prevComp = comp, currentRef) => {
		const pos = landingComps.indexOf(comp);
		let updateData = {};
		let isUpdate = false;
		setCurrElem(currentRef);
		for (let i = 0; i <= pos; i++) {
			const compname = landingComps[i];
			if (!visibleComps[compname]) {
				isUpdate = true;
				updateData[compname] = true;
				setVisibleComps((prevState) => ({
					...prevState,
					[compname]: true
				}));
			}
		}
		if (isUpdate) {

		}
	}, [visibleComps]);

	const requestDemoClick = useCallback((e) => {
		toggleModal(MODAL_NAMES.REQ_DEMO);
		trackClickEvents('request_demo_clicked');
	}, [toggleModal]);

	const botClickHandler = useCallback((state) => {
		setDetailBox(state);
		if (isDropOff && !state) {
			toggleModal(false);
			setLocalStorage('hideDropoff', true);
		}
	}, [isDropOff]);

	const footerClickHandler = useCallback(() => {
		setLocalStorage('hideDropoff', true);
	}, [isDropOff]);

	/**
	 * onSignupComplete - Callback function to be executed after the signup process is complete
	 */
	const onSignupComplete = useCallback(() => {
		if (autoPopupAttempted && isAutoPopup) {
			trackClickEvents('myBiz_signup_auto_popup_success', 'prop44');
		}
	}, [autoPopupAttempted, isAutoPopup]);

	useEffect(() => {
		let { emailId: sendEmail, inviteCode: invitationCode } = decodeInviteCode(invitecode);
		try {
			sendEmail = email && !invitecode ? window.atob(email) : sendEmail;
		} catch (error) {

		}

		const mybizData = {
			email: sendEmail,
			isDecision: sendEmail ? true : false,
			inviteCode: invitationCode
		};
		if (redirectUrl) {
			mybizData.ssoQueryParams = `redirectUrl=${redirectUrl}`;
		}
		setMybizData((data) => ({
			...data,
			...mybizData
		}));
		if (signup || isInvite) {
			onLoginClicked(false);
		}
	}, [email, signup, invitecode, isInvite, redirectUrl]);

	useEffect(() => {
		let scrollTimer;

		if (hash) {
			scrollTimer = setTimeout(() => {
				loadscrollToElem(hash.replace('#', ''));
			});
		}

		return () => {
			scrollTimer && clearTimeout(scrollTimer);
		};
	}, []);

	useEffect(() => {
		const checkMoveOutside = (e) => {
			if (e.clientY < 2 && !activeModal && !bizModal && !hideDropoff) {
				openDropoff();
			}
		};
		if (enableDropffPop && !hideDropoff) {
			window.addEventListener('mouseout', checkMoveOutside);
		}
		return () => {
			window.removeEventListener('mouseout', checkMoveOutside);
		};
	}, [enableDropffPop, activeModal, bizModal, hideDropoff]);

	useEffect(() => {
		if (sessionExpiry || userInactivity) {
			clearLoginState();
		}
	}, [sessionExpiry, userInactivity]);

	/* Signup auto popup - Show popup automatically after 5 seconds */
	useEffect(() => {
		let timer;

		/* Signup auto popup - Check if bizModal has no value, user is not logged in, auto-popup hasn't been attempted and user on desktop */
		if (!bizModal && !isLoggedInUser && !autoPopupAttempted && !isMobileView) {
			timer = setTimeout(() => {
				onLoginClicked(false);
				setIsAutoPopup(true);
				setAutoPopupAttempted(true); // Mark that the auto-popup has been attempted
				trackClickEvents('myBiz_signup_auto_popup_viewed', 'prop44');
			}, APPLICATION_CONSTANTS.AUTO_SIGNUP_POPUP_DELAY);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [bizModal, autoPopupAttempted, isMobileView]);

	useEffect(() => {
		let timer = setTimeout(() => {
			setBot((isContactUs || showChatBot), true);
		}, isContactUs ? 0 : 5000);

		return () => {
			timer && clearTimeout(timer);
		};
	}, [showChatBot, isContactUs]);

	const setBot = (showBot, loadBot) => {
		setShowBot(showBot);
		setIsloadBot(loadBot);
	};

	useEffect(() => {
		if (modal) {
			toggleModal(modal);
		}
	}, [modal]);

	const providerValue = useMemo(() => ({
		onSignUpClicked,
		toggleModal,
		bizModal,
		currElem,
		isLoggedInUser,
		appSsrData: contextData,
		referralCode
	}), [onLoginClicked, toggleModal, bizModal, currElem, isLoggedInUser, contextData]);

	const dispVal = useMemo(() => ({
		isMobile: isMobileView ? Devices.mobile : Devices.desktop,
		isLandScape: isLandScape
	}), [isMobileView, isLandScape]);

	const headerConfigData = useMemo(() => ({
		onNavClick: navClick,
		menuWidth: '800px',
		scrollOffset: scrollOffset,
		logoLink: isSME ? '' : PATHS.MYBIZ_URL,
		isLoggedInUser,
		menus: [
			{
				btnTxt: isLoggedInUser ? SIGNUP_BTN_TEXT.loggedin : (isMobileView ? SIGNUP_BTN_TEXT.mobilemenuLogin : SIGNUP_BTN_TEXT.menuLogin),
				loggedInCta: SIGNUP_BTN_TEXT.loggedin,
				btnType: 'Flat',
				btnName: 'Primary',
				btnShadow: true,
				btnWidth: isMobileView ? false : '165px',
				height: '48px',
				handleClick: signinClicked,
				right_M: headerActions.showDemo ? 16 : 0,
				bottom_M: 5,
				show: headerActions.showSignup,
				seoUrl: PATHS.LANDING_LOGIN_PAGE
			},
			{
				btnTxt: 'REQUEST DEMO',
				btnType: 'Flat',
				btnName: 'Secondary',
				btnShadow: true,
				btnWidth: isMobileView ? false : '155px',
				height: '48px',
				handleClick: requestDemoClick,
				show: headerActions.showDemo,
				seoUrl: PATHS.REQUEST_DEMO_PAGE_NEW
			}
		],
		...headerConfig

	}), [navClick, signinClicked, requestDemoClick, isMobileView, scrollOffset]);

	if (mobileLogin) {
		return (
			<ThemeProvider theme={Theme}>
				<MobileLanding />
			</ThemeProvider>
		);
	} else {
		return (
			<ThemeProvider theme={Theme}>
				<LandingProvider value={providerValue}>
					<DisplayProvider
						value={dispVal}>
						<GlobalStyle />
						<HiddenElem activeModal={activeModal} className='mobileApp'>
							{loadHeader &&
								<HeaderWrap>
									{showHeaderLoader && <CircularLoader pageLoader />}
									<DHeader
										showNav={false}
										activeModal={activeModal}
										myBizOnlyLogin={isMyBizLogin}
										onModalChange={onModalClose}
										onLoginFlowComplete={onLoginComplete}
										myBizExperiments={mybizExperiments}
										myBizData={myBizData}
										onSignupFlowComplete={onSignupComplete}
									/>
								</HeaderWrap>}
						</HiddenElem>
						<Header
							isLanding={isLanding}
							loadHeader={loadHeader}
							preloadHeader={setLoadHeader}
							headerLinks={headerLinks}
							headerConfig={headerConfigData}
							navOverHandler={navOverHandler}
						/>
						<AppWrapper>
							{children && React.cloneElement(children, {
								isScollView,
								updateShowComp,
								visibleComps,
								navClick,
								updateCurrElem: setCurrElem,
								params,
								isPreLoad
							})}
						</AppWrapper>
						{showFooter && <Footer isPreLoad={isPreLoad} />}
						{false &&
							<ChatBot
								showBot={showBot}
								showHelpSection={isDropOff}
								popupTitle={isDropOff ? `We're Sorry to see you leave!` : false}
								botClickHandler={botClickHandler}
								radioField={landingRadioFields}
								requestFormPopUpProp={detailBox}
								pathUrl={PATHS.UNAUTH_REQ_CALLBACK}
								footerClickHandler={footerClickHandler}
								isMobile={isMobileView}
							/>}
						{bizModal === MODAL_NAMES.REQ_DEMO && <RequestDemo closeModal={toggleModal} />}
					</DisplayProvider>
				</LandingProvider>
			</ThemeProvider>
		);
	}
};

export default LandingTemplate;
